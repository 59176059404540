import { uuid } from '@leland-dev/leland-ui-library';

import {
  type Class,
  type OneOnOneSession,
  type Scalars,
} from '../__generated-gql-types__/globalTypes';

import mxpnl from './analytics/mixpanel';
import { CLARITY_SESSION_ID_KEY } from './constants';

export const isSessionOneOnOne = <
  C extends Pick<Class, '__typename'>,
  O extends Pick<OneOnOneSession, '__typename'>,
>(
  session: C | O | undefined,
): session is O => {
  return session?.__typename === 'OneOnOneSession';
};

export const getSessionType = <
  C extends Pick<Class, '__typename'>,
  O extends {
    __typename?: 'OneOnOneSession';
    introOrder?: { id: string } | null;
  },
>(
  session: C | O | undefined,
): 'IntroCall' | 'OneOnOneSession' | 'FreeEvent' | 'Class' => {
  if (isSessionOneOnOne(session)) {
    return session.introOrder ? 'IntroCall' : 'OneOnOneSession';
  }
  if (session?.__typename === 'Class') {
    return 'Class';
  }
  return 'FreeEvent';
};

export const trackCoachingSessionScheduled = (props: {
  attendees: Array<Scalars['ApplicantUrn']['output']>;
  coachUrn: Scalars['CoachUrn']['output'];
  sessionUrn: Scalars['CoachingSessionUrn']['output'];
  startAt: number;
}) =>
  mxpnl.track('coachingSessionScheduled', {
    ...props,
    applicantUrn: props.attendees,
  });

export const trackIntroCallScheduled = (props: {
  coachUrn: Scalars['CoachUrn']['output'];
  sessionUrn: Scalars['CoachingSessionUrn']['output'];
  startAt: number;
  createdAt: number;
}) =>
  mxpnl.track('introCallScheduled', {
    ...props,
  });

export const trackCoachingSessionAttended = (props: {
  coachUrn: Scalars['CoachUrn']['output'];
  sessionUrn: Scalars['CoachingSessionUrn']['output'];
  startAt: number;
  createdAt: number;
}) =>
  mxpnl.track('coachingSessionAttended', {
    ...props,
  });

export const getSessionId = (): string => {
  if (typeof window === 'undefined') {
    return '';
  }

  let sessionId = window.sessionStorage.getItem(CLARITY_SESSION_ID_KEY);
  if (!sessionId) {
    sessionId = uuid();
    window.sessionStorage.setItem(CLARITY_SESSION_ID_KEY, sessionId);
  }
  return sessionId;
};
