import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useAuth } from '../context/AuthContext';
import { getSessionId } from '../utils/session';

declare global {
  interface Window {
    clarity: (command: string, ...args: string[]) => void;
  }
}

export const ClaritySession: React.FC = () => {
  const { currentUser } = useAuth();
  const router = useRouter();

  // This needs to be run on every page, so we need to depend on pathname.
  useEffect(() => {
    if (!currentUser || typeof window.clarity === 'undefined') return;

    const sessionId = getSessionId();
    if (sessionId) {
      window.clarity('identify', currentUser.id, sessionId);
    }
  }, [currentUser, router.pathname]);

  return null;
};
