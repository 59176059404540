import { GoalName, type Scalars } from '../__generated-gql-types__/globalTypes';

import { categorySlugToUrn } from './category';

/**
 * Check whether the current env is server, as opposed to browser
 */
export const isServer = typeof window === 'undefined';

export const __DEV__ = process.env.NODE_ENV !== 'production';
export const __STAGING__ =
  process.env.NEXT_PUBLIC_APPLICATION_ENV !== 'production';

export const APPLICANT_SITE_URL =
  process.env.NEXT_PUBLIC_LELAND_APPLICANT_URL ??
  (__DEV__
    ? 'https://local.leland-staging.com:3000'
    : __STAGING__
      ? 'https://www.leland-staging.com'
      : 'https://www.joinleland.com');

export const LELAND_DOMAIN =
  __DEV__ || __STAGING__ ? '.leland-staging.com' : '.joinleland.com';

export const AUTH_REDIRECT_URI = `${APPLICANT_SITE_URL}/auth/callback`;
export const SITE_URL = '';
export const LIBRARY_URL = `${SITE_URL}/library`;
export const SUPPORT_URL = `${SITE_URL}/support`;
export const SUBSCRIPTION_CHOOSER_URL = `${SITE_URL}/plan-summary`;
export const EVENTS_RELATIVE_URL = '/events';

export const COACH_SITE_URL =
  process.env.NEXT_PUBLIC_LELAND_COACH_URL ?? 'https://coach.joinleland.com';
export const COACH_PROFILE_URL =
  process.env.NEXT_PUBLIC_LELAND_COACH_PROFILE_URL ??
  'https://coach.joinleland.com/profile';

export const COMPANY_INSTAGRAM_URL = 'https://www.instagram.com/joinleland/';
export const COMPANY_LINKEDIN_URL =
  'https://www.linkedin.com/company/joinleland/';
export const COMPANY_TIKTOK_URL = 'https://www.tiktok.com/@joinleland';

export const SUPPORT_TYPEFORM_URL =
  'https://joinleland.typeform.com/to/TY5OgGwK';

export const BASE_LELAND_GO_URL = process.env.NEXT_PUBLIC_LELAND_GO_URL;

export const PARTNERSHIPS_LP_URL = `${BASE_LELAND_GO_URL}/partner/partnerships`;

export const LELAND_PLUS_REDIRECT_URL = '/leland-plus';

export const BASE_LELAND_PLUS_MARKETING_URL = `${BASE_LELAND_GO_URL}/plus`;

export const SUBSCRIBER_DASHBOARD_URL = '/subscriber/dashboard';

export const ORDER_HISTORY_URL = '/settings/order-history';

export const DEFAULT_ERROR_MESSAGE =
  'Oops! Something went wrong. Please try again. If the issue persists, contact Leland support at support@joinleland.com.';

export const DEFAULT_CLASS_IMAGE = '/images/class-placeholder-image.png';
export const DEFAULT_PACKAGE_IMAGE = '/images/package-placeholder-image.png';
export const DEFAULT_SCHOOL_IMAGE = '/images/school.png';
export const DEFAULT_COMPANY_IMAGE = '/images/company.png';

export const BACKUP_CATEGORY_URL = '/images/categories-placeholder.png';

export const MBA_CATEGORY_SLUG = 'mba';
export const MBA_CATEGORY_URN = categorySlugToUrn(MBA_CATEGORY_SLUG);
export const DEFERRED_MBA_SUBCATEGORY_SLUG = 'deferred-mba';
export const GRE_CATEGORY_SLUG = 'gre';
export const GRE_CATEGORY_URN = categorySlugToUrn(GRE_CATEGORY_SLUG);
export const MANAGEMENT_CONSULTING_CATEGORY_SLUG = 'management-consulting';
export const MANAGEMENT_CONSULTING_CATEGORY_URN = categorySlugToUrn(
  MANAGEMENT_CONSULTING_CATEGORY_SLUG,
);
export const PRIVATE_EQUIY_CATEGORY_SLUG = 'private-equity';
export const PRIVATE_EQUIY_CATEGORY_URN = categorySlugToUrn(
  PRIVATE_EQUIY_CATEGORY_SLUG,
);
export const INVESTMENT_BANKING_CATEGORY_SLUG = 'investment-banking';
export const INVESTMENT_BANKING_CATEGORY_URN = categorySlugToUrn(
  INVESTMENT_BANKING_CATEGORY_SLUG,
);
export const COLLEGE_CATEGORY_SLUG = 'college';
export const COLLEGE_CATEGORY_URN = categorySlugToUrn(COLLEGE_CATEGORY_SLUG);
export const PRODUCT_MANAGEMENT_CATEGORY_SLUG = 'product-management';
export const PRODUCT_MANAGEMENT_CATEGORY_URN = categorySlugToUrn(
  PRODUCT_MANAGEMENT_CATEGORY_SLUG,
);
export const GENERAL_CAREER_CATEGORY_SLUG = 'career-development';
export const GENERAL_CAREER_CATEGORY_URN = categorySlugToUrn(
  GENERAL_CAREER_CATEGORY_SLUG,
);
export const DENTAL_SCHOOL_CATEGORY_SLUG = 'dental-school';
export const DENTAL_SCHOOL_CATEGORY_URN = categorySlugToUrn(
  DENTAL_SCHOOL_CATEGORY_SLUG,
);
export const DAT_CATEGORY_SLUG = 'dat';
export const DAT_CATEGORY_URN = categorySlugToUrn(DAT_CATEGORY_SLUG);
export const MEDICAL_SCHOOL_CATEGORY_SLUG = 'medical-school';
export const MEDICAL_SCHOOL_CATEGORY_URN = categorySlugToUrn(
  MEDICAL_SCHOOL_CATEGORY_SLUG,
);
export const SWE_CATEGORY_SLUG = 'software-engineering';
export const SWE_CATEGORY_URN = categorySlugToUrn(SWE_CATEGORY_SLUG);
export const AI_CATEGORY_SLUG = 'ai-ml';
export const AI_CATEGORY_URN = categorySlugToUrn(AI_CATEGORY_SLUG);
export const CRYPTO_CATEGORY_SLUG = 'blockchain-crypto-web3';
export const CRYPTO_CATEGORY_URN = categorySlugToUrn(CRYPTO_CATEGORY_SLUG);
export const DATA_SCIENCE_CATEGORY_SLUG = 'data-science';
export const DATA_SCIENCE_CATEGORY_URN = categorySlugToUrn(
  DATA_SCIENCE_CATEGORY_SLUG,
);
export const WEB_DEV_CATEGORY_SLUG = 'web-development';
export const WEB_DEV_CATEGORY_URN = categorySlugToUrn(WEB_DEV_CATEGORY_SLUG);
export const MASTERS_CATEGORY_SLUG = 'masters-programs';
export const MASTERS_PROGRAMS_URN = categorySlugToUrn(MASTERS_CATEGORY_SLUG);

export const DEVELOPMENT_URNS = [
  AI_CATEGORY_URN,
  CRYPTO_CATEGORY_URN,
  DATA_SCIENCE_CATEGORY_URN,
  WEB_DEV_CATEGORY_URN,
  SWE_CATEGORY_URN,
];

export const SUBSCRIBER_DUMMY_COACH_URN = 'urn:coach:60b2cb327b8ead4a78c16fc0';

export const SUBSCRIBER_DUMMY_COACH = {
  id: SUBSCRIBER_DUMMY_COACH_URN,
  user: {
    id: SUBSCRIBER_DUMMY_COACH_URN,
    firstName: 'Leland',
    lastName: 'Team',
    pictureLink:
      'https://lh3.googleusercontent.com/a/AAcHTtdGKf2FjVq6OPmaOkVm2N-8xTSW52OfcZsAviemteMbEg=s96-c"',
    mostRecentOrder: null,
    hourlyRate: null,
  },
};

export const GOAL_NAMES: GoalName[] = Object.values(GoalName);

export const TARGET_SCHOOLS: Array<{
  id: Scalars['SchoolUrn']['output'];
  name: string;
}> = [
  {
    id: 'urn:school:60aaaeef051a4b7d21d1946b',
    name: 'Stanford Graduate School of Business',
  },
  {
    id: 'urn:school:60aaaeef051a4b7d21d1946c',
    name: 'Harvard Business School',
  },
  {
    id: 'urn:school:60aaaeef051a4b7d21d1946d',
    name: 'The Wharton School (UPenn)',
  },
  {
    id: 'urn:school:60aaaeef051a4b7d21d19470',
    name: 'MIT Sloan',
  },
  {
    id: 'urn:school:60aaaeef051a4b7d21d1946e',
    name: 'Kellogg School of Management (Northwestern)',
  },
  {
    id: 'urn:school:60aaaeef051a4b7d21d1946f',
    name: 'Chicago Booth',
  },
  {
    id: 'urn:school:60aaaeef051a4b7d21d19471',
    name: 'Columbia Business School',
  },
  {
    id: 'urn:school:60aaaeef051a4b7d21d19472',
    name: 'Haas School of Business (Berkeley)',
  },
  {
    id: 'urn:school:60aaaeef051a4b7d21d19473',
    name: 'Yale School of Management',
  },
  {
    id: 'urn:school:60aaaeef051a4b7d21d19475',
    name: 'NYU Stern School of Business',
  },
];

export const POSITIVE_INT_PATTERN = /^[1-9]\d*$/;
export const INVALID_NUMERIC_KEYS = new Set(['E', 'e', '+', '-', '.']);

const FEATURED_COACHES_STAGING: Record<
  Scalars['CategoryUrn']['output'],
  string[]
> = Object.freeze({
  [MBA_CATEGORY_URN]: ['jiashuo-wang', 'john-koelliker'],
  [COLLEGE_CATEGORY_URN]: ['jiashuo-wang', 'john-koelliker'],
  'urn:category:medical-school': ['jiashuo-wang', 'john-koelliker'],
  'urn:category:law-school': ['jiashuo-wang', 'john-koelliker'],
});

const FEATURED_COACHES_PROD: Record<
  Scalars['CategoryUrn']['output'],
  string[]
> = Object.freeze({
  [MBA_CATEGORY_URN]: ['jiashuo-wang', 'john-koelliker'],
  [COLLEGE_CATEGORY_URN]: ['jiashuo-wang', 'john-koelliker'],
  'urn:category:medical-school': ['jiashuo-wang', 'john-koelliker'],
  'urn:category:law-school': ['jiashuo-wang', 'john-koelliker'],
});

export const FEATURED_COACHES =
  __DEV__ || __STAGING__ ? FEATURED_COACHES_STAGING : FEATURED_COACHES_PROD;

type ExperienceLevelMap = Record<number, string>;
export const GENERIC_EXPERIENCE_LEVELS: ExperienceLevelMap = Object.freeze({
  1: 'Intern',
  3: 'Analyst',
  4: 'Associate',
  5: 'Manager',
  6: 'Director',
  8: 'Vice President',
  10: 'Executive',
});

export const SPECIFIC_EXPERIENCE_LEVELS: Record<
  Scalars['CategoryUrn']['output'],
  ExperienceLevelMap
> = Object.freeze({
  'urn:category:product-management': {
    1: 'Product Manager Intern',
    2: 'Associate Product Manager',
    3: 'Product Manager',
    5: 'Group Product Manager',
    6: 'Director of Product',
    8: 'VP of Product',
    10: 'Chief Product Officer',
  },
  'urn:category:management-consulting': {
    1: 'Intern',
    3: 'Analyst',
    4: 'Associate',
    5: 'Consultant',
    6: 'Case Team Leader',
    8: 'Associate Partner',
    10: 'Partner or Managing Director',
  },
  'urn:category:investment-banking': {
    1: 'Intern',
    3: 'Analyst',
    4: 'Associate',
    6: 'Senior Associate',
    8: 'Vice President',
    10: 'Partner or Managing Director',
  },
  'urn:category:private-equity': {
    1: 'Intern',
    3: 'Analyst',
    4: 'Associate',
    6: 'Senior Associate',
    8: 'Vice President',
    10: 'Partner or Managing Director',
  },
  'urn:category:venture-capital': {
    1: 'Intern',
    3: 'Analyst',
    4: 'Associate',
    6: 'Senior Associate',
    7: 'Angel Investor',
    8: 'Principal',
    10: 'Partner',
  },
});

export const CACHE_CONTROL = !__STAGING__
  ? 'public, s-maxage=60, stale-while-revalidate=86400'
  : 'public, s-maxage=60, stale-while-revalidate=3600';

export enum FEATURED_SCHOOL_FILTERS {
  IVY = 'Ivy+',
  COMMON_APP = 'Common App',
  UCS = 'UCs',
  UTAH_SCHOOLS = 'Utah Schools',
  TOP_ENGINEERING = 'Top Engineering Schools',
  APPLY_TEXAS = 'ApplyTexas',
}

export const FEATURED_SCHOOL_FILTERS_SCHOOL_NAMES: Record<
  FEATURED_SCHOOL_FILTERS,
  string
> = Object.freeze({
  [FEATURED_SCHOOL_FILTERS.IVY]: 'Ivy League+',
  [FEATURED_SCHOOL_FILTERS.COMMON_APP]: 'Common App',
  [FEATURED_SCHOOL_FILTERS.UCS]: 'UC Schools',
  [FEATURED_SCHOOL_FILTERS.UTAH_SCHOOLS]: 'Utah Schools',
  [FEATURED_SCHOOL_FILTERS.TOP_ENGINEERING]: 'Top Engineering Schools',
  [FEATURED_SCHOOL_FILTERS.APPLY_TEXAS]: 'Apply Texas',
});

export const FEATURED_FILTERS: Record<
  Scalars['CategoryUrn']['output'],
  FEATURED_SCHOOL_FILTERS[]
> = Object.freeze({
  [COLLEGE_CATEGORY_URN]: Object.values(FEATURED_SCHOOL_FILTERS),
});

export const FEATURED_FILTERS_ITEMS: Record<
  FEATURED_SCHOOL_FILTERS,
  Array<Scalars['SchoolUrn']['output']>
> = Object.freeze({
  [FEATURED_SCHOOL_FILTERS.IVY]: [
    // Ivy
    'urn:school:60a4a82235b4a535fc5f6bbc', // Brown
    'urn:school:60a4a82235b4a535fc5f6c22', // Columbia
    'urn:school:60a4a82235b4a535fc5f6c2d', // Cornell
    'urn:school:60a4a82235b4a535fc5f6c38', // Dartmouth
    'urn:school:60a4a82235b4a535fc5f6cab', // Harvard
    'urn:school:60a4a82235b4a535fc5f6dcb', // Princeton
    'urn:school:60a4a82235b4a535fc5f6f0e', // UPenn
    'urn:school:60a4a82235b4a535fc5f6fb6', // Yale
    // +
    'urn:school:60a4a82235b4a535fc5f6d2d', // MIT
    'urn:school:60a4a82235b4a535fc5f6d92', // Northwestern
    'urn:school:60a4a82235b4a535fc5f6e3c', // Stanford
    'urn:school:60a4a82235b4a535fc5f6eb5', // U of Chicago
  ],
  [FEATURED_SCHOOL_FILTERS.COMMON_APP]: [],
  [FEATURED_SCHOOL_FILTERS.UCS]: [
    'urn:school:60a4a82235b4a535fc5f6ea7', // UC Berkeley
    'urn:school:60a4a82235b4a535fc5f6ea8', // UC Davis
    'urn:school:60a4a82235b4a535fc5f6ea9', // UC Irvine
    'urn:school:60a4a82235b4a535fc5f6eaa', // UCLA
    'urn:school:60a4a82235b4a535fc5f6eab', // UC Merced
    'urn:school:60a4a82235b4a535fc5f6eac', // UC Riverside
    'urn:school:60a4a82235b4a535fc5f6ead', // UC San Diego
    'urn:school:60a4a82235b4a535fc5f6eae', // UC San Francisco
    'urn:school:60a4a82235b4a535fc5f6eaf', // UC Santa Barbara
    'urn:school:60a4a82235b4a535fc5f6eb0', // UC Santa Cruz
  ],
  [FEATURED_SCHOOL_FILTERS.UTAH_SCHOOLS]: [
    'urn:school:60a4a82235b4a535fc5f6bb8', // BYU
    'urn:school:60a4a82235b4a535fc5f6f41', // University of Utah
    'urn:school:60a4a82235b4a535fc5f6f58', // Utah State University
    'urn:school:60a4a82235b4a535fc5f6b0b', // Utah Valley University
    'urn:school:60a4a82235b4a535fc5f6e2e', // Southern Utah University
    'urn:school:60a4a82235b4a535fc5f6f7c', // Weber State University
  ],
  [FEATURED_SCHOOL_FILTERS.TOP_ENGINEERING]: [
    'urn:school:60a4a82235b4a535fc5f6bc3', // Caltech
    'urn:school:60a4a82235b4a535fc5f6be1', // Carnegie Mellon
    'urn:school:60a4a82235b4a535fc5f6c8d', // Georgia Tech
    'urn:school:60a4a82235b4a535fc5f6d2d', // MIT
    'urn:school:60a4a82235b4a535fc5f6e3c', // Stanford
    'urn:school:60a4a82235b4a535fc5f6ea7', // UC Berkeley
    'urn:school:60a4a82235b4a535fc5f6ee9', // University of Michigan
  ],
  [FEATURED_SCHOOL_FILTERS.APPLY_TEXAS]: [
    'urn:school:60a4a82235b4a535fc5f6b5e', // Abilene Christian University
    'urn:school:60a4a82235b4a535fc5f6b73', // Angelo State University
    'urn:school:60a4a82235b4a535fc5f6b8a', // Austin College
    'urn:school:60a4a82235b4a535fc5f6b99', // Baylor University
    // Concordia University - concordia.edu
    'urn:school:60a4a82235b4a535fc5f6c34', // Dallas Baptist University
    // Hardin-Simmons University - hsutx.edu
    // Houston Christian University - hc.edu
    // Huston-Tillotson University - htu.edu
    'urn:school:60a4a82235b4a535fc5f6cf8', // Lamar University
    'urn:school:60a4a82235b4a535fc5f6d04', // LeTourneau University
    'urn:school:60a4a82235b4a535fc5f6d2f', // McMurry University
    'urn:school:60a4a82235b4a535fc5f6d44', // Midwestern State University
    'urn:school:60a4a82235b4a535fc5f6dab', // Our Lady of the Lake University
    'urn:school:60a4a82235b4a535fc5f6dc7', // Prairie View A&M University
    'urn:school:60a4a82235b4a535fc5f6e08', // Sam Houston State University
    // Schreiner University - schreiner.edu
    'urn:school:60a4a82235b4a535fc5f6e29', // Southern Methodist University
    'urn:school:60a4a82235b4a535fc5f6e34', // Southwestern University
    'urn:school:60a4a82235b4a535fc5f6df5', // Saint Edward’s University
    // St. Mary’s University - stmarytx.edu
    'urn:school:60a4a82235b4a535fc5f6e52', // Stephen F. Austin State University
    'urn:school:60a4a82235b4a535fc5f6e61', // Sul Ross State University
    'urn:school:60a4a82235b4a535fc5f6e68', // Tarleton State University

    /* Texas A&M University System */
    // Texas A&M University - Central Texas
    'urn:school:60a4a82235b4a535fc5f6e6f', // Texas A&M University - College Station
    'urn:school:60a4a82235b4a535fc5f6e70', // Texas A&M University - Commerce
    'urn:school:60a4a82235b4a535fc5f6e71', // Texas A&M University - Corpus Christi
    // Texas A&M University - Galveston
    'urn:school:60a4a82235b4a535fc5f6e72', // Texas A&M University - Kingsville
    // Texas A&M University - Laredo
    // Texas A&M University - San Antonio
    // Texas A&M University - Texarkana

    'urn:school:60a4a82235b4a535fc5f6e73', // Texas Christian University
    // Texas Lutheran University
    'urn:school:60a4a82235b4a535fc5f6e74', // Texas Southern University
    'urn:school:60a4a82235b4a535fc5f6e75', // Texas State University
    'urn:school:60a4a82235b4a535fc5f6e76', // Texas Tech University
    // Texas Wesleyan University
    'urn:school:60a4a82235b4a535fc5f6e78', // Texas Woman’s University
    'urn:school:60a4a82235b4a535fc5f6e86', // Trinity University
    'urn:school:60a4a82235b4a535fc5f6f3b', // University of the Incarnate Word
    'urn:school:60a4a82235b4a535fc5f6ebc', // University of Dallas
    'urn:school:60a4a82235b4a535fc5f6ecb', // University of Houston
    'urn:school:60a4a82235b4a535fc5f6f09', // University of North Texas
    'urn:school:60a4a82235b4a535fc5f6f1b', // University of Saint Thomas

    /* University of Texas System */
    'urn:school:60a4a82235b4a535fc5f6f2f', // UT Arlington
    'urn:school:60a4a82235b4a535fc5f6f30', // UT Austin
    'urn:school:60a4a82235b4a535fc5f6f32', // UT Dallas
    'urn:school:60a4a82235b4a535fc5f6f33', // UTEP
    // UT Permian Basin
    // UT Rio Grande Valley
    'urn:school:60a4a82235b4a535fc5f6f34', // UT San Antonio
    'urn:school:60a4a82235b4a535fc5f6f35', // UT Tyler

    'urn:school:60a4a82235b4a535fc5f6f98', // West Texas A&M University
  ],
});

export const TYPEFORM_URLS = {
  REQUEST_MORE_CATEGORIES: 'https://joinleland.typeform.com/to/Z5Fqp28i',
  GET_RECOMMENDATIONS: 'https://joinleland.typeform.com/to/qpRd5hLm',
  TALK_TO_TEAM_MEMBER: 'https://joinleland.typeform.com/to/a4DMXJjV',
  MANAGE_SUBSCRIPTION: 'https://joinleland.typeform.com/to/MKqIsCrH',
  CUSTOMER_SUPPORT: 'https://joinleland.typeform.com/to/TY5OgGwK',
} as const;

export const MEET_WITH_TEAM_MEMBER_CAL_URL =
  'https://calendly.com/d/4cs-hhf-zsx/meet-with-the-leland-team';

export const STRATEGY_CALL_CAL_URL =
  'https://calendly.com/d/g27-xst-p69/strategy-call';

export const COMPREHENSIVE_PACKAGE_NAME = 'Comprehensive';

export const CLARITY_SESSION_ID_KEY = 'clarity_session_id';

export const MAGOOSH_CATEGORY_URLS: Record<
  Scalars['SkuUrn']['output'],
  string
> = {
  'urn:sku:magoosh-gmat': 'https://gmat.magoosh.com/dashboard',
  'urn:sku:magoosh-gre': 'https://gre.magoosh.com/dashboard',
  'urn:sku:magoosh-lsat': 'https://lsat.magoosh.com/dashboard',
  'urn:sku:magoosh-act': 'https://act.magoosh.com/dashboard',
  'urn:sku:magoosh-sat': 'https://sat.magoosh.com/dashboard',
  'urn:sku:magoosh-toefl': 'https://toefl.magoosh.com/dashboard',
};
